import Project from "./components/Project";

const WhatsNew = () => {
  return (
    <div className="flex w-full">
      <Project />
    </div>
  );
};

export default WhatsNew;
