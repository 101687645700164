import { isDev } from "helpers/dev";

const Footer = () => {
  return (
    <div className="flex w-full px-1 pb-8 pt-3 lg:px-8 justify-center xl:justify-end">
      <div>
        <ul className="flex flex-wrap items-center gap-3 sm:flex-nowrap md:gap-10">
          <li>
            <p className="mb-4 text-center text-sm text-gray-600 sm:!mb-0 md:text-base">
              © {1900 + new Date().getYear()} MVA Team. All Rights Reserved. ({isDev ? "dev" : "prod"} build)
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
