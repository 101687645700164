import React from "react";
import { Route, Navigate } from 'react-router-dom';

// Admin Imports
import MainDashboard from "views/admin/default";
import WhatsNew from "views/admin/whatsnew";
// import Users from "views/admin/users";

// Auth Imports
import AuthLayout from "layouts/auth";

import { useAuth } from "modules/auth";

// Icon Imports
import {
  MdHome,
  MdNewReleases,
  // MdSupervisorAccount
} from "react-icons/md";


export const ProtectedRoute = ({
  isAllowed,
  children,
}) => {
  if (!isAllowed) {
    return <Navigate to={<AuthLayout />} replace />;
  }
  return children;
};


export const getRoutes = (layout) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { currentUser } = useAuth();
  return routes.map((prop, key) => {
    if (prop.layout === layout) {
      if (prop.private) {
        return (
          <Route
            path={prop.path}
            element={<ProtectedRoute isAllowed={!!currentUser}>{prop.component}</ProtectedRoute>}
            key={key}
          />
        );
      } else {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      }
    } else {
      return null;
    }
  });
};

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  // {
  //   name: "Users",
  //   layout: "/admin",
  //   path: "users",
  //   icon: <MdSupervisorAccount className="h-6 w-6" />,
  //   component: <Users />,
  // },
  {
    name: "What's new",
    layout: "/admin",
    path: "whats-new",
    icon: <MdNewReleases className="h-6 w-6" />,
    component: <WhatsNew />,
  },
];
export default routes;
