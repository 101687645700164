import './Calendar.css';

const Day = ({ day, selected, onOpen }) => {

  // const [popoverShow, setPopoverShow] = useState(false);
  // const [tooltipShow, setTooltipShow] = useState(false);

  // const togglePopover = () => {
  //   // setPopoverShow(!popoverShow);
  // }

  const toggleTooltip = () => {
    // setTooltipShow(!tooltipShow);
  }

  return (
    <div className={
        "day" +
        (day.isToday ? " today" : "") +
        (day.isCurrentMonth ? "" : " differentMonth") +
        (day.date.isSame(selected) ? " selected" : "") +
        (day.hasEvents ? " hasEvents" : "") } > 

      {!day.hasEvents ? 
      <div className="dayNumber">{day.number}</div> 
      : (day.hasEvents && day.link) 
      ? 
      <>
        <a
          rel="noopener noreferrer"
          target="_blank"
          onMouseEnter={toggleTooltip}
          onMouseOut={toggleTooltip}
          id={`Tooltip${day.number}`}
          href={day.link ? day.link : "#"} 
          className="dayNumber"> {day.number}
          {day.itemStyle ? 
            <span 
              style={{backgroundColor: `${day.itemStyle}`}} 
              className="calendarDot">
            </span> : "" }
        </a>
        {/* <Tooltip 
          placement="top" 
          isOpen={this.state.tooltipShow} 
          toggle={this.toggleTooltip} 
          target={`Tooltip${day.number}`}
          html="">
          {day.title}<br/>- Title
        </Tooltip> */}
      </> 
      : (day.hasEvents && !day.link)
      ? 
        <>
          <div
            onClick={() => onOpen(day)}
            id={`Popover${day.number}`}
            className={`dayNumber ${day.data.isCancelled && "dayCancelled"}`}> {day.number}
              {day.itemStyle && !day.data.isCancelled ? 
                <span 
                  style={{backgroundColor: `${day.itemStyle}`}} 
                  className="calendarDot">
                </span> : "" }
          </div>
          {/* <Popover 
            placement="top" 
            isOpen={this.state.popoverShow} 
            target={`Popover${day.number}`} 
            toggle={this.togglePopover}>
            <PopoverHeader>{day.title}</PopoverHeader>
            <PopoverBody>{day.info}</PopoverBody>
          </Popover>  */}
        </> 
      : "" }
    
    </div>
  );
}

export default Day;