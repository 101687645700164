import { ref, get, set, serverTimestamp } from "firebase/database";
import { database } from "modules/firebase";

export const getUsers = async () => {
  const usersRef = ref(database, "users")

  try {
    const snapshot = await get(usersRef)
    const data = snapshot.val();
    return data;
  } catch (error) {
      console.error("Error executing query: ", error);
  }
}

export const getUser = async (userId) => {
  const usersRef = ref(database, `users/${userId}`);
  let user = null;
  try {
    const snapshot = await get(usersRef);
    user = snapshot.val();
    if (user) { 
      user.id = userId;
    }
  } catch (error) {
    // expected behaviour for permission denied -> user doesn't have access
    if (error.message.toLowerCase() !== "permission denied")
    {
      throw error;
    }
  }
  return user;
}

export const insertAnonymousUser = async (user) => {
  const anonymousRef = ref(database, `anonymous/${user.uid}`);
  let userData = user.providerData[0];
  userData.registeredDate = serverTimestamp();
  try {
    return await set(anonymousRef, userData);
  } catch (error) {
    console.error("Error executing query: ", error);
  }
}