import { FcGoogle } from "react-icons/fc";
import { useAuth } from "modules/auth";
import logo from "assets/img/auth/logo.png";

export default function SignIn() {

  const { login } = useAuth();

  return (
    <div className="flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-5 text-4xl font-bold text-navy-700 dark:text-white text-center">
          MVA TEAM
        </h4>
        <div className="flex items-center justify-center mb-5">
          <img className="w-[200px]" src={logo} alt="..."/>
        </div>
        <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800" onClick={() => login()}>
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div>
      </div>
    </div>
  );
}
