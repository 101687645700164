import { useEffect, useState, useRef } from "react";
import Dropdown from "components/dropdown";
import InputField from "components/fields/InputField";
import { AiOutlineUser } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlineShop, AiFillCloseSquare } from "react-icons/ai";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure
} from "@chakra-ui/react";
import { useAuth } from "modules/auth";
import Switch from "components/switch";
import moment from 'moment';

const CardMenuCurrentEvent = ({transparent, onAddNonRegularPlayer, onEditParticipants, onCancelEvent, event}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [nonRegularPlayerId, setNonRegularPlayerId] = useState('');
  const [cancelledReason, setCancelledReason] = useState('');
  const [participants, setParticipants] = useState(event.participants);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null)
  const [menuItem, setMenuItem] = useState('');

  const { users, currentUser } = useAuth();

  useEffect(() => {
    // Update the copy when the prop changes
    setParticipants(event.participants);
  }, [event]);

  const statusChanged = (userId) => {
    const updatedParticipants = participants.map((participant) => {
      if (participant.userId === userId) {
        return {
          ...participant,
          status: participant.status === "yes" ? "no" : "yes" // Update the field value
        };
      }
      return participant; // For other objects, return them unchanged
    });
    setParticipants(updatedParticipants)
  }

  const addNonRegularPlayer = () => {
    if (nonRegularPlayerId.trim() === "") return;
    onAddNonRegularPlayer(capitalizeFirstLetter(nonRegularPlayerId));
    setNonRegularPlayerId("");
    onClose();
  }

  const editParticipants = () => {
    onEditParticipants(event.date, participants);
    onClose();
  }
  
  const cancelEvent = () => {
    onCancelEvent(event.date, cancelledReason);
    onClose();
  }

  const onOpenMenu = (item) => {
    setMenuItem(item);
    onOpen();
  }

  const onCloseMenu = () => {
    setNonRegularPlayerId("");
    setParticipants(event.participants);
    onClose();
  }

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const cancel = () => {
    onCloseMenu();
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onCloseMenu} initialFocusRef={initialRef}>
        <ModalOverlay />
          <ModalContent margin={6} rounded="xl" bg="#111c44">
          {event && (
          <ModalHeader color="white">
            {menuItem === "edit_attendance" && <span>Edit attendance for {moment(event.date, 'YYYYMMDD').format("DD. MMM YYYY")}</span>}
            {menuItem === "add_non_regular_player" && <span>Add non-regular player for {moment(event.date, 'YYYYMMDD').format("DD. MMM YYYY")}</span>}
            {menuItem === "cancel_event" && <span>Do you want to cancel {moment(event.date, 'YYYYMMDD').format("DD. MMM YYYY")}?</span>}
          </ModalHeader>
          )}
          <ModalCloseButton color="white" bg="#1B254B" />
          {menuItem === "edit_attendance" && event && (
          <div className="px-6">
            <div className="flex flex-col my-4">
              {participants.map((participant, index) => (
              <div key={index} className="mt-3 flex items-center gap-3">
                <Switch id={`switch${index}`} checked={participant.status === "yes"} onChange={() => statusChanged(participant.userId)}/>
                <label
                  htmlFor={`checkbox${index}`}
                  className="ml-2 text-base font-medium text-navy-700 dark:text-white"
                >
                  {participant.isRegistered ? users[participant.userId].displayName : participant.userId}
                </label>
              </div>
              ))}
            </div>
            <div className="flex justify-center pt-6">
              <button
                href=""
                className={`border-2 dark:text-white font-bold rounded-lg px-10 py-3 hover:bg-green-500 transition duration-200`}
                onClick={() => editParticipants()}
              >
                Save
              </button>
            </div>
          </div>)}
          {menuItem === "add_non_regular_player" && event && (
          <div className="px-6 flex flex-col">
            <InputField
              forwardRef={initialRef}
              placeholder="Enter firstname and lastname..."
              type="text"
              value={nonRegularPlayerId}
              onInputChange={(value) => setNonRegularPlayerId(value)}
            />

              <div className="flex justify-center pt-6">
                <button
                  href=""
                  className={`border-2 font-bold rounded-lg px-10 py-3 dark:text-white hover:bg-green-500 transition duration-200`}
                  onClick={() => addNonRegularPlayer()}
                >
                  Add
                </button>
              </div>
          </div>)}
          {menuItem === "cancel_event" && event && (
          <div className="px-6 flex flex-col">
            <InputField
              forwardRef={initialRef}
              placeholder="Enter reason (optional)..."
              type="text"
              value={cancelledReason}
              onInputChange={(value) => setCancelledReason(value)}
            />
            <div className="flex justify-center pt-6">
              <button
                href=""
                className={`border-2 font-bold rounded-lg px-10 py-3 dark:text-white hover:bg-green-500 transition duration-200`}
                onClick={() => cancelEvent()}
              >
                Yes
              </button>
            </div>
          </div>)}
          <div className="flex justify-center py-6">
            <button
              onClick={cancel}
              className="linear rounded-xl border-2 border-red-500 px-4 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
            >
              Close
            </button>
          </div>
        </ModalContent>
      </Modal>
      <Dropdown
        button={
          <button
            onClick={() => setOpenMenu(!openMenu)}
            open={openMenu}
            className={`flex items-center text-xl hover:cursor-pointer ${
              transparent
                ? "bg-none text-white hover:bg-none active:bg-none"
                : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
            } linear justify-center rounded-lg font-bold transition duration-200`}
          >
            <BsThreeDots className="h-6 w-6" />
          </button>
        }
        animation={"origin-top-right transition-all duration-300 ease-in-out"}
        classNames={`${transparent ? "top-8" : "top-11"} right-0 w-max`}
        children={
          <div className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <button disabled={event.isCancelled} className="flex items-center gap-2 text-gray-600 font-bold" onClick={() => onOpenMenu("add_non_regular_player")}>
              <span>
                <AiOutlineUser />
              </span>
              Add non-regular player
            </button>
            {currentUser.isModerator && (
            <>
              <button disabled={event.isCancelled} className="flex items-center gap-2 text-gray-600 font-bold mt-3" onClick={() => onOpenMenu("edit_attendance")}>
                <span>
                  <AiOutlineShop />
                </span>
                Edit attendance
              </button>
              <button disabled={event.isCancelled} className="flex items-center gap-2 text-gray-600 font-bold mt-3" onClick={() => onOpenMenu("cancel_event")}>
                <span>
                  <AiFillCloseSquare />
                </span>
                Cancel event
              </button>
            </>)}
          </div>
        }
      />
    </>
  );
}

export default CardMenuCurrentEvent;
