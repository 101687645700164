import React from "react";
import image1 from "assets/img/auth/logo.png";
import Card from "components/card";

const Project = () => {
  return (
    <Card extra={"w-full p-4 h-full"}>
      <div className="mb-8 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          All releases
        </h4>
      </div>
      <div className="flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        <div className="flex items-center">
          <div className="">
            <img className="h-[83px] w-[83px] rounded-lg" src={image1} alt="" />
          </div>
          <div className="ml-4">
            <p className="text-base font-medium text-navy-700 dark:text-white">
              [1.0.0] - 15.09.2023
            </p>
            <p className="my-2 text-sm font-medium text-gray-300">
              Redesign and restart of a new MVA info page
            </p>
              <ul className="text-sm">
                <li>- New design</li>
                <li>- Confirming attendance for next event</li>
                <li>- Mini calendar to see & confirm attendance for all upcoming events</li>
              </ul>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Project;
