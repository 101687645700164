import { query, ref, push, update, orderByKey, startAt, endAt, limitToFirst, get } from "firebase/database";
import { database } from "modules/firebase";
import moment from 'moment'


export const getCalendarEventsNew = async (date, users, fetchOutOfCurrentMonth) => {
  const today = moment();
  const startOfMonth = date.startOf('month').format('YYYYMMDD');
  const endOfMonth   = date.endOf('month').format('YYYYMMDD');
  const eventsQuery = query(ref(database, "events"), orderByKey(), startAt(startOfMonth), endAt(endOfMonth));
  const nextEventQuery = query(ref(database, "events"), orderByKey(), startAt(today.format("YYYYMMDD")), limitToFirst(1))

  let events = [];

  try {
    
    const eventsQuerySnapshot = await get(eventsQuery);
    eventsQuerySnapshot.forEach((child) => {
      const date = child.key;
      const value = child.val();
      let participants = []

      if (value.participants) {
        for (const key in value.participants) {
          const participant_value = value.participants[key];
          // Append the key to the object
          participant_value.id = key;
          participants.push(participant_value);
        }
      }
      
      const event = { date, participants, isCancelled: value.isCancelled, cancelledReason: value.cancelledReason };
      Object.keys(users).forEach((user_id) => {
        if (!event.participants.some((item) => item.userId === user_id)) {
          event.participants.push({
            isRegistered: true,
            status: "",
            updatedBy: "",
            updated: null,
            userId: user_id,
            id: null,
          });
        }
      });
      applySort(event);
      events.push(event);
    });

    if (fetchOutOfCurrentMonth && (events.length === 0 || today.format("YYYYMMDD") > events[events.length - 1].date)) {
      const nextEventQuerySnapshot = await get(nextEventQuery);
      nextEventQuerySnapshot.forEach((child) => {
      const date = child.key;
      const value = child.val();
      let participants = []

      if (value.participants) {
        for (const key in value.participants) {
          const participant_value = value.participants[key];
          // Append the key to the object
          participant_value.id = key;
          participants.push(participant_value);
        }
      }
      const event = { date, participants, isCancelled: value.isCancelled, cancelledReason: value.cancelledReason };
      Object.keys(users).forEach((user_id) => {
        if (!event.participants.some((item) => item.userId === user_id)) {
          event.participants.push({
            isRegistered: true,
            status: "",
            updatedBy: "",
            updated: null,
            userId: user_id,
            id: null,
          });
        }
      });
      applySort(event);
      events.push(event);
    });
    }
    return events;
  } catch (error) {
    console.error("Error executing query: ", error);
  }
}

export const applySort = (event) => {
  const statusOrder = ["yes", "maybe", "no", ""];
  const customSort = (a, b) => {
    const statusA = statusOrder.indexOf(a.status);
    const statusB = statusOrder.indexOf(b.status);

    // If the statuses are different, return the comparison result
    if (statusA !== statusB) {
      return statusA - statusB;
    }
    // If the statuses are the same, compare by "number" in descending order
    return b.updated - a.updated;
  }

  event.participants.sort(customSort);
}

export const getEvent = async (users) => {
  const startDate = moment().format('YYYYMMDD')
  const my_query = query(ref(database, "events"), orderByKey(), startAt(startDate), limitToFirst(1))
  
  try {
    const querySnapshot = await get(my_query);
    let runningEvent = null;
    
    if (querySnapshot.exists())
    {
      const data = querySnapshot.val();
      runningEvent = Object.values(data)[0];
      runningEvent.date = Object.keys(data)[0];

      let participants = [];
      for (const key in runningEvent.participants) {
        const participant_value = runningEvent.participants[key];
        // Append the key to the object
        participant_value.id = key;
        participants.push(participant_value);
      }
      runningEvent.participants = participants;
      Object.keys(users).forEach((user_id) => {
        if (!runningEvent.participants.some((item) => item.userId === user_id) && users[user_id].isActivePlayer) {
          runningEvent.participants.push({
            isRegistered: true,
            status: "",
            updatedBy: "",
            updated: null,
            userId: user_id,
            id: null,
          });
        }
      });
      applySort(runningEvent);
    }
    return runningEvent;
  } catch (error) {
    console.error("Error executing query: ", error);
  }
}

export const addParticipantAttendance = (date, status, userId, id, userIdUpdate, isRegistered) =>
{
  const newData = {
    isRegistered: isRegistered,
    updated: moment().unix(),
    updatedBy: userIdUpdate,
    status: status,
    userId: userId,
  };

  try {
    if (id) {
      const dbPathRef = ref(database, `events/${date}/participants/${id}`);
      update(dbPathRef, {
        updated: newData.updated,
        updatedBy: newData.updatedBy,
        status: newData.status,
      })
      newData.id = id;
    } else {
      const dbPathRef = ref(database, `events/${date}/participants`);
      const dataRef = push(dbPathRef, newData);
      newData.id = dataRef.key;
    }
    return newData;
  } catch (error) {
    console.error("Error executing query: ", error);
  }
}

export const setCancelledEvent = (date, reason, userId) =>
{
  try {
    const dbPathRef = ref(database, `events/${date}/`);
    const data = {
      isCancelled: true,
      cancelledReason: reason,
      cancelledBy: userId,
    }
    update(dbPathRef, data);
    return data;
  } catch (error) {
    console.error("Error executing query: ", error);
  }
}