import React, { useState, useRef } from 'react'
import DayNames from './DayNames'
import { v4 as uuidv4 } from 'uuid';
import Week from './Week'
import moment from 'moment/moment'
import "./Calendar.css";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { MdChevronLeft, MdChevronRight, MdBlock } from "react-icons/md";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure
} from "@chakra-ui/react";
import { useAuth } from 'modules/auth';


const Calendar = ({onMonthChanged, events, onAttendanceConfirm, onCancelEvent}) => {

  const [selectedMonth, setSelectedMonth] = useState(moment());
  const [selectedDay] = useState(moment().startOf("day"));
  const [selectedEventDay, setSelectedEventDay] = useState();
  const [selectedSection, setSelectedSection] = useState('CONFIRMATION');
  const [cancelledReason, setCancelledReason] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentUser } = useAuth();

  const initialRef = useRef(null)
  

  const previous = () => {
		const previousMonth = selectedMonth.clone().subtract(1, "month");
    setSelectedMonth(previousMonth);
    onMonthChanged(previousMonth);
  }

  const next = () =>  {
		const nextMonth = selectedMonth.clone().add(1, "month");
    setSelectedMonth(nextMonth);
    onMonthChanged(nextMonth);
  }

  const closeModal = () => {
    setSelectedSection("CONFIRMATION");
    setCancelledReason("");
    onClose();
  }

	const confirmMyAttendance = (status) => {
		onAttendanceConfirm(selectedEventDay.data.date, status, currentUser.id);
		closeModal();
	}

  const cancelEvent = () => {
    onCancelEvent(selectedEventDay.data.date, cancelledReason);
    closeModal();
  }

  const isInThePast = (eventData) => {
    return eventData.data.date < moment().format("YYYYMMDD")
  }

  const onEventDateOpen = (eventData) => {
    setSelectedEventDay(eventData);
    if (isInThePast(eventData)) {
      // setSelectedSection("ATTENDANCE");
      return;
    }
    onOpen();
  }

  const onSectionClick = (section) => {
    setSelectedSection(section);
  }

  const getSelectedDateStatus = () => {
    if (!selectedEventDay) return null;
    const me = selectedEventDay.data.participants.find((participant) => participant.userId === currentUser.id);
    if (me) return me.status;
    return null;
  }

  const getDotColor = (event) => {
    const foundMe = event.participants.find(p => p.userId === currentUser.id)
    if (foundMe) {
      if (foundMe.status === "yes") {
        return "lightgreen";
      }
      if (foundMe.status === "no") {
        return "red";
      }
      if (foundMe.status === "maybe") {
        return "orange";
      }
    }
    return "white";
  }
  
  const renderMonthLabel = () =>  {
    return (
      <span className="calendarItemContainer monthLabel">
        {selectedMonth.format("MMMM YYYY")}
      </span>
    );
  }
  
  const renderWeeks = () =>  {
    const currentMonthView = selectedMonth;
    const currentSelectedDay = selectedDay;

		const monthEvents = events.map((event) => {
			const year = event.date.substring(0, 4); 
			const month = event.date.substring(4, 6);
			const day = event.date.substring(6, 8);
			return {
				title: "MVA",
				info: "MVA rekreacija",
				itemStyle: getDotColor(event),
				link: "",
				date: moment(`${year}-${month}-${day}`, "YYYY-MM-DD"),
				data: event,
			}
		});

    let weeks = [];
    let done = false;
    let previousCurrentNextView = currentMonthView
      .clone()
      .startOf("month")
      .subtract(1, "d")
      .day("Monday");
    let count = 0;
    let monthIndex = previousCurrentNextView.month();

    while (!done) {
      weeks.push(
        <Week
          key={uuidv4()}
          selectedMonthEvents={monthEvents}
          previousCurrentNextView={previousCurrentNextView.clone()}
          currentMonthView={currentMonthView}
          selected={currentSelectedDay}
          onOpen={onEventDateOpen}
        />
      );
      previousCurrentNextView.add(1, "w");
      done = count++ > 2 && monthIndex !== previousCurrentNextView.month();
      monthIndex = previousCurrentNextView.month();
    }
    return weeks;
  }

  return (
    <div>
      <Modal isOpen={isOpen} onClose={closeModal} initialFocusRef={initialRef}>
        <ModalOverlay />
        {selectedEventDay &&
        <ModalContent margin={6} rounded="xl" bg="#111c44">
          <ModalHeader color="white">
            Event date {selectedEventDay.date.format("DD. MMM YYYY")}
          </ModalHeader>
          <div className="flex items-center justify-center mt-2 mb-6 px-6">
            {!isInThePast(selectedEventDay) &&
            <div className="text-center mx-2"><button onClick={() => onSectionClick("CONFIRMATION")} className={`border-2 border-gray-300 text-gray-300 font-bold p-2 rounded-lg ${selectedSection === "CONFIRMATION" && "bg-brand-500"}`}>CONFIRMATION</button></div>
            }
            {/* {!selectedEventDay.data.isCancelled &&
            <div className="text-center mx-2"><button onClick={() => onSectionClick("ATTENDANCE")} className={`border-2 border-gray-300 text-gray-300 font-bold p-2 rounded-lg ${selectedSection === "ATTENDANCE" && "bg-brand-500"}`}>ATTENDANCE</button></div>
            } */}
            {!isInThePast(selectedEventDay) && !selectedEventDay.data.isCancelled && currentUser.isModerator &&
            <div className="text-center mx-2"><button onClick={() => onSectionClick("CANCEL")} className={`border-2 border-gray-300 text-gray-300 font-bold p-2 rounded-lg ${selectedSection === "CANCEL" && "bg-brand-500"}`}>CANCEL</button></div>
            }
          </div>
          <div className="px-3 mb-6">
            <hr />
          </div>
          <ModalCloseButton color="white" bg="#1B254B" />
          {selectedSection === "CONFIRMATION" && 
          <>
            <div className="text-white text-xl font-bold flex justify-center mb-2">Confirm attendance for {selectedEventDay.date.format("DD. MMM YYYY")}:</div>
            {!selectedEventDay.data.isCancelled &&
            <div className="flex items-center justify-between p-6">
              <button 
                href=""
                className={`border-2 text-white font-bold rounded-lg px-4 py-2 ${getSelectedDateStatus() !== "yes" ? 'hover:bg-green-500' : 'bg-green-500'} transition duration-200`}
                onClick={() => confirmMyAttendance("yes")}
              >
                Yes
              </button>
              <button
                href=""
                className={`border-2 text-white font-bold rounded-lg px-4 py-2 ${getSelectedDateStatus() !== "maybe" ? 'hover:bg-orange-500' : 'bg-orange-500'} transition duration-200`}
                onClick={() => confirmMyAttendance("maybe")}
              >
                Maybe?
              </button>
              <button
                href=""
                className={`border-2 text-white font-bold rounded-lg px-4 py-2 ${getSelectedDateStatus() !== "no" ? 'hover:bg-red-600' : 'bg-red-600'} transition duration-200`}
                onClick={() => confirmMyAttendance("no")}
              >
                No
              </button>
            </div>
            }
            {selectedEventDay.data.isCancelled &&
            <div>
              <p className="text-base text-[#E3DAFF] text-center font-bold">
              Event is <span className="text-red-500">CANCELLED!</span>
              </p>
              <div className="flex justify-center my-4">
                <MdBlock className="text-red-500 text-3xl" />
              </div>
              {selectedEventDay.data.cancelledReason &&
              <p className="text-center font-bold text-white">
                Reason: <span className="font-normal">{selectedEventDay.data.cancelledReason}</span>
              </p>
            }
            </div>
            }
          </>
          }
          {selectedSection === "ATTENDANCE" && 
          <>
          </>
          }
          {selectedSection === "CANCEL" && 
          <>
            <div className="text-white text-xl font-bold flex justify-center">Do you want to cancel {selectedEventDay.date.format("DD. MMM YYYY")}?</div>
            <div className="px-6">
            <InputField
              forwardRef={initialRef}
              placeholder="Enter reason (optional)..."
              type="text"
              value={cancelledReason}
              onInputChange={(value) => setCancelledReason(value)}
            />
            </div>
            <div className="flex justify-center pt-6">
              <button
                href=""
                className={`border-2 font-bold rounded-lg px-10 py-3 dark:text-white hover:bg-green-500 transition duration-200`}
                onClick={() => cancelEvent()}
              >
                Yes
              </button>
            </div>
          </>
          }
          <div className="flex justify-center py-6">
            <button
              onClick={closeModal}
              className="linear rounded-xl border-2 border-red-500 px-4 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
            >
              Close
            </button>
          </div>
        </ModalContent>
        }
      </Modal>
      <Card extra="flex w-full h-full flex-col px-3 py-3">
        <div className="calendarRectangle">
          <div>
            <section className="mainCalendar">
              <header className="calendarHeader">
                <div className="calendarRow titleHeader" style={{paddingBottom: 20}}>
                  <button className="calendar__navigation__prev-button" onClick={previous}>
                    <MdChevronLeft className="m-1 h-6 w-6 " />
                  </button>
                  <div className="calendarItemContainer" style={{fontWeight: 'bold', fontSize: 18}}>
                    {renderMonthLabel()}
                  </div>
                  <button className="calendar__navigation__next-button" onClick={next}>
                    <MdChevronRight className="m-1 h-6 w-6 " />
                  </button>
                </div>
                <DayNames />
              </header>
              <div className="daysContainer">
                {renderWeeks()}
              </div>
            </section>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default Calendar;

