import logo from "assets/img/auth/logo.png";

export default function Waiting() {
  return (
    <div className="flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-5 text-4xl font-bold text-navy-700 dark:text-white text-center">
          MVA TEAM
        </h4>
        <div className="flex items-center justify-center mb-5">
          <img className="w-[200px]" src={logo} alt="..."/>
        </div>
        <div className="flex p-3 w-full text-center rounded-xl font-bold items-center justify-center gap-2 bg-lightPrimary dark:bg-navy-600 dark:text-white">
          You will be in soon!<br/>
          You just need to wait for confirmation from Administrator.<br/>
          If you have any problems or you're waiting for too long, send a message in MVA Team WhatsApp group.
        </div>
      </div>
    </div>
  );
}
