import { useState, useEffect, useRef } from "react";
import { useAuth } from "modules/auth";
import { addParticipantAttendance, applySort, setCancelledEvent } from "modules/events";
import Calendar from "components/calendar";
import CurrentEvent from "views/admin/default/components/CurrentEvent";
import { getCalendarEventsNew } from "modules/events";
import moment from "moment";


const Dashboard = () => {

	const { users, currentUser } = useAuth();
	const [events, setEvents] = useState([]);
	const loadedMonths = useRef([]);

	useEffect(() => {
		const fetchEvents = async () => {
			const calendarEvents = await getCalendarEventsNew(moment(), users, true);
			loadedMonths.current.push(moment().format("MM"));
			setEvents(calendarEvents);
		}
		fetchEvents();
  }, [users]);


  const onMonthChanged = async (date) => {
		const monthAlreadyFetched = loadedMonths.current.includes(date.format("MM"));
		if (monthAlreadyFetched) return;

		const monthEvents = await getCalendarEventsNew(date, users, false);

		const updatedEvents = [...events];
		monthEvents.forEach((newEvent) => {
			let insertIndex = 0;
			// Find the correct position to insert or update the new element
  		while (insertIndex < updatedEvents.length) {
    		const existingElement = updatedEvents[insertIndex];
    
    		if (existingElement.date === newEvent.date) {
					// If the property value matches, update the existing element
					updatedEvents[insertIndex] = newEvent;
					return;
				} else if (existingElement.date > newEvent.date) {
					// Use splice() to insert the new element at the correct position
					updatedEvents.splice(insertIndex, 0, newEvent);
					return;
				}
    		insertIndex++;
  		}
			if (insertIndex === updatedEvents.length) {
				updatedEvents.push(newEvent);
			}
		});
		loadedMonths.current.push(date.format("MM"));
		setEvents(updatedEvents);
	};

	const getCurrentEvent = () => {
		const today = moment().format("YYYYMMDD");
		const next = events.find((event) => event.date >= today)
		if (next) return next;
		return events[events.length - 1]
	}

	const addAttendance = (date, status, userId, isRegistered=true) => {
		const event = events.find(e => e.date === date);
		const participant = event.participants.find(p => p.userId === userId);
		if (participant && (status === participant.status)) return;

		const participantData = addParticipantAttendance(event.date, status, userId, participant ? participant.id : null, currentUser.id, isRegistered);
		let participants = [...event.participants]
		const indexP = participants.findIndex(p => p.userId === userId);
    if (indexP !== -1) {
      // If the object exists, update it
      participants[indexP] = participantData;
    } else {
      // If the object doesn't exist, insert it
      participants.push(participantData);
    }
		const updatedEvent = {...event, participants }
		applySort(updatedEvent);
		
		const updatedEvents = [...events]
		const indexE = updatedEvents.findIndex(e => e.date === updatedEvent.date);
		updatedEvents[indexE] = updatedEvent;
		setEvents(updatedEvents);
	}
	
	const editParticipants = (date, participants) => {
		const indexE = events.findIndex(e => e.date === date);

		const updatedParticipants = events[indexE].participants.map((participant) => {
			const changedParticipant = participants.find((chp) => chp.userId === participant.userId);
			if (changedParticipant.status === "yes" && participant.status !== "yes") {
				return addParticipantAttendance(date, "yes", changedParticipant.userId, changedParticipant.id, currentUser.id, changedParticipant.isRegistered);
			} else if (changedParticipant.status === "no" && participant.status === "yes") {
				return addParticipantAttendance(date, "no", changedParticipant.userId, changedParticipant.id, currentUser.id, changedParticipant.isRegistered);
			} else {
				return participant;
			}
		})
		const updatedEvent = {...events[indexE], participants: updatedParticipants};
		applySort(updatedEvent);
		
		const updatedEvents = [...events];
		updatedEvents[indexE] = updatedEvent;
		setEvents(updatedEvents);
	}

	const cancelEvent = (date, reason) => {
		const indexE = events.findIndex(e => e.date === date);

		const updatedData = setCancelledEvent(date, reason, currentUser.id);

		const updatedEvents = [...events];
		updatedEvents[indexE] = {...updatedEvents[indexE], ...updatedData}
		setEvents(updatedEvents);
	}


  return (
    <div className="flex flex-col md:flex-row gap-5">
      {events && (
			<>
				<div className="md:w-1/2">
					<CurrentEvent event={getCurrentEvent()} onCancelEvent={cancelEvent} onAttendanceConfirm={addAttendance} onEditParticipants={editParticipants} />
				</div>
				<div className="md:w-1/4 md:max-w-[375px] md:min-w-[250px]">
					<Calendar onMonthChanged={onMonthChanged} events={events} onAttendanceConfirm={addAttendance} onCancelEvent={cancelEvent} />
				</div>
			</>
			)}
    </div>
  );
}

export default Dashboard;
