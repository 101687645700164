import React from 'react'
import Day from './Day'
import { v4 as uuidv4 } from 'uuid';
import './Calendar.css';

const Week = ({ previousCurrentNextView, selected, selectedMonthEvents, currentMonthView, onOpen }) => {

  let days = [];
  let date = previousCurrentNextView;

  for (var i = 0; i < 7; i++) {
    let dayHasEvents = false,
        title = '',
        info  = '',
        itemStyle = '',
        link  = '',
        data = {};

    for (var j = 0; j < selectedMonthEvents.length; j++) {
      if (selectedMonthEvents[j].date.isSame(date, "day")) {
        dayHasEvents = true;
        title = selectedMonthEvents[j].title ? selectedMonthEvents[j].title : '';
        info  = selectedMonthEvents[j].info ? selectedMonthEvents[j].info : '';
        itemStyle = selectedMonthEvents[j].itemStyle ? selectedMonthEvents[j].itemStyle : '';
        link  = selectedMonthEvents[j].link ? selectedMonthEvents[j].link : '';
        data = selectedMonthEvents[j].data ? selectedMonthEvents[j].data : {};
      }
    }

    let day = {
      name: date.format("dd").substring(0, 1),
      number: date.date(),
      isCurrentMonth: date.month() === currentMonthView.month(),
      isToday: date.isSame(new Date(), "day"),
      date: date,
      hasEvents: dayHasEvents,
      title: title, 
      info: info,
      itemStyle: itemStyle,
      link: link,
      data: data,
    };
    
    days.push(<Day 
      key={uuidv4()}
      day={day} 
      selected={selected}
      onOpen={onOpen}
      />);
      date = date.clone();
      date.add(1, "d");
  }

  return (
    <div className="calendarRow week">
      {days}
    </div>
  );
}

export default Week