import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";

import Loader from "components/loader/Loader";

import { useAuth } from 'modules/auth';

const App = () => {
  const { loading, currentUser } = useAuth();
  
  if (loading) {
    return (
      <Loader />
    );
  }

  if (!currentUser) {
    return (
      <Routes>
        <Route path="/" element={<AuthLayout />} />
        <Route path="*" element={<Navigate to="/" replace/>} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="admin/*" element={<AdminLayout />} />
      <Route path="/" element={<Navigate to="/admin" replace />} />
      <Route path="*" element={<Navigate to="/admin"/>} />
    </Routes>
  );
};

export default App;
