import SignIn from "views/auth/SignIn";
import Waiting from "views/auth/Waiting";
import { useAuth } from "modules/auth";

export default function Auth() {

  const { waiting } = useAuth();

  return (
    <div className="h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
      {waiting ? <Waiting /> : <SignIn />}
    </div>
  );
}
