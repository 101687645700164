import React from 'react'
import './Calendar.css';

const DayNames = () => {
  return (
    <div className="calendarRow daysHeader">
      <span className="calendarItemContainer dayName">MON</span>
      <span className="calendarItemContainer dayName">TUE</span>
      <span className="calendarItemContainer dayName">WED</span>
      <span className="calendarItemContainer dayName">THU</span>
      <span className="calendarItemContainer dayName">FRI</span>
      <span className="calendarItemContainer dayName">SAT</span>
      <span className="calendarItemContainer dayName">SUN</span>
    </div>
  );
}

export default DayNames;