import CardMenuCurrentEvent from "components/card/CardMenuCurrentEvent";
import Card from "components/card";
import { MdCheckCircle, MdCancel, MdOutlineHelp, MdBlock } from "react-icons/md";
import Progress from "components/progress";
import { useAuth } from "modules/auth";
import moment from 'moment';

const CurrentEvent = ({event, onAttendanceConfirm, onEditParticipants, onCancelEvent }) => {
  
  const { users, currentUser } = useAuth();

  const confirmMyAttendance = (status) => {
    onAttendanceConfirm(event.date, status, currentUser.id);
  }

  const onAddNonRegularPlayer = (userIdAsName) => {
    onAttendanceConfirm(event.date, "yes", userIdAsName, null, false);
  }

  console.log("EVENT", event);

  const getCounts = () => {
    let returnObject = {
      yes: 0,
      no: 0,
      maybe: 0,
    }
    if (event) {
      (event.participants || []).forEach(p => {
        switch (p.status) {
          case "yes":
            returnObject.yes += 1;
            break;
          case "no":
            if (p.isRegistered) returnObject.no += 1;
            break;
          case "maybe":
            returnObject.maybe += 1;
            break;
          default:
            break;
        }
      });
    }
    return returnObject;
  }

  const extractMyStatus = () => {
    let myStatus = {};
    if (event) {
      return event.participants.find((participant) => (participant.userId === currentUser.id)) || {};
    }
    return myStatus;
  }

  const counts = getCounts();
  const myStatus = extractMyStatus();

  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
      <div className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          MVA rekreacija {event && moment(event.date, 'YYYYMMDD').format("DD. MMM YYYY")}
        </div>
        {event && !event.isCancelled &&
        <CardMenuCurrentEvent onCancelEvent={onCancelEvent} onAddNonRegularPlayer={onAddNonRegularPlayer} onEditParticipants={onEditParticipants} event={event} />
        }
      </div>
      {event && event.isCancelled && 
      <div className="p-4 rounded-lg mt-[10px]">
        <p className="mb-[10px] text-base text-[#E3DAFF] text-center font-bold">
          Event for {event && moment(event.date, 'YYYYMMDD').format("DD. MMM YYYY")} is <span className="text-red-500">CANCELLED!</span>
        </p>
        <div className="flex justify-center my-4">
          <MdBlock className="text-red-500 text-3xl" />
        </div>
        {event.cancelledReason &&
        <p className="text-center text-base font-bold">
          Reason: <span className="font-normal">{event.cancelledReason}</span>
        </p>
        }
      </div>
      }
      {event && !event.isCancelled &&
      <>
        <div className={`p-4 rounded-lg mt-[10px] ${myStatus.status === "" && currentUser.isActivePlayer ? 'bg-brand-400' : ''}`}>
          <p className="mb-[10px] text-base text-[#E3DAFF] text-center font-bold">
            Confirm attendance for {event && moment(event.date, 'YYYYMMDD').format("DD. MMM YYYY")}:
          </p>
          <div className="mt-[20px] mx-auto max-w-[400px]">
            <div className="flex items-center justify-between gap-4">
              <button 
                href=""
                className={`border-2 font-bold rounded-lg px-4 py-2 ${myStatus.status !== "yes" ? 'hover:bg-green-500' : 'bg-green-500'} transition duration-200`}
                onClick={() => confirmMyAttendance("yes")}
              >
                Yes
              </button>
              <button
                href=""
                className={`border-2 font-bold rounded-lg px-4 py-2 ${myStatus.status !== "maybe" ? 'hover:bg-orange-500' : 'bg-orange-500'} transition duration-200`}
                onClick={() => confirmMyAttendance("maybe")}
              >
                Maybe?
              </button>
              <button
                href=""
                className={`border-2 font-bold rounded-lg px-4 py-2 ${myStatus.status !== "no" ? 'hover:bg-red-600' : 'bg-red-600'} transition duration-200`}
                onClick={() => confirmMyAttendance("no")}
              >
                No
              </button>
            </div>
          </div>
        </div>
        <div className="mt-8 flex gap-14 items-center justify-center">
          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl font-bold text-navy-700 dark:text-white">
              {counts.yes}
            </p>
            <p className="text-sm font-normal text-gray-600">Yes</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl font-bold text-navy-700 dark:text-white">
              {counts.maybe}
            </p>
            <p className="text-sm font-normal text-gray-600">Maybe</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl font-bold text-navy-700 dark:text-white">
              {counts.no}
            </p>
            <p className="text-sm font-normal text-gray-600">No</p>
          </div>
        </div>
        <div className="mt-8 overflow-x-scroll xl:overflow-hidden">
          <table className="w-full">
            <thead>
              <tr>
                <th
                  className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                >
                  <p className="text-xs tracking-wide text-gray-600">
                    NAME
                  </p>
                </th>
                <th
                  className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                >
                  <p className="text-xs tracking-wide text-gray-600">
                    STATUS
                  </p>
                </th>
                <th
                  className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                >
                  <p className="text-xs tracking-wide text-gray-600">
                    UPDATED
                  </p>
                </th>
                <th
                  className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                >
                  <p className="text-xs tracking-wide text-gray-600">
                    PROGRESS
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {event.participants.map((participant, index) => {
              if (participant.status === "" && participant.isRegistered && !users[participant.userId].isActivePlayer) return null;
              if (participant.status === "no" && !participant.isRegistered) return null;
              return (
                <tr key={index}>
                  <td
                    className="pt-[14px] pb-[18px] sm:text-[14px]"
                  >
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {participant.isRegistered ? users[participant.userId].displayName : participant.userId}
                    </p>
                  </td>
                  <td
                    className="pt-[14px] pb-[18px] sm:text-[14px]"
                  >
                    <div className="flex items-center gap-2">
                      <div className={`rounded-full text-xl`}>
                        {participant.status === "yes" ? (
                          <MdCheckCircle className="text-green-500" />
                        ) : participant.status === "no" ? (
                          <MdCancel className="text-red-500" />
                        ) : participant.status === "maybe" ? (
                          <MdOutlineHelp className="text-orange-500" />
                        ) : null}
                      </div>
                    </div>
                  </td>
                  <td
                    className="pt-[14px] pb-[18px] sm:text-[14px]"
                  >
                    {participant.updated &&
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {moment.unix(participant.updated).format("DD. MMM YYYY HH:mm")}
                    </p>
                    }
                  </td>
                  <td
                    className="pt-[14px] pb-[18px] sm:text-[14px]"
                  >
                    {participant.isRegistered && <Progress width="w-[108px]" value={0} />}
                  </td>
                </tr>
              )})}
            </tbody>
          </table>
        </div>
      </>}
    </Card>
  );
};

export default CurrentEvent;
