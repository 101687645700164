import React from "react";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import {
  IoIosLogOut
} from "react-icons/io";
import { useAuth } from "modules/auth";

const Navbar = (props) => {
  const { onOpenSidenav, brandText } = props;
  const { currentUser, logout } = useAuth();

  return (
    <nav className="top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1">
          <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            Pages
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[130px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[140px] md:flex-grow-0 md:gap-1 xl:w-[140px] xl:gap-2">
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        <span>
          <p className="cursor-pointer" onClick={() => logout()}>
            <IoIosLogOut className="h-6 w-6 text-gray-600 dark:text-white" />
          </p>
        </span>
        <span>
        <img
              className="h-10 w-10 rounded-full"
              src={currentUser ? currentUser.photoURL : ""}
              alt="me"
            />
        </span>
        
      </div>
    </nav>
  );
};

export default Navbar;
