import { createContext, useContext, useEffect, useState } from "react";
import { signInWithRedirect, GoogleAuthProvider } from "firebase/auth";
import { getUsers, getUser, insertAnonymousUser } from "modules/users";
import { auth } from "../firebase";
// import { isDev } from "helpers/dev";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(true);
  const [waiting, setWaiting] = useState(false);


  function login() {
    // signInWithPopup(auth, new GoogleAuthProvider())
    signInWithRedirect(auth, new GoogleAuthProvider());
  }

  function logout() {
    // setLoading(true);
    return auth.signOut();
  }

  const loadDataForUsers = async () => {
    const users = await getUsers()
    setUsers(users);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (potentialUser) => {
      // console.log(`FIRING onAuthStateChanged: ${potentialUser}`);
      if (potentialUser) {
        const user = await getUser(potentialUser.uid);
        if (user) {
          await loadDataForUsers();
        } else {
          await insertAnonymousUser(potentialUser);
          setWaiting(true);
        }
        setCurrentUser(user)
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    loading,
    waiting,
    login,
    logout,
    users,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
